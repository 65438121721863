<template>
  <ion-page>
    <ion-content class="cWhite ion-padding">
      <div class="maxWidth">
        <img
          class="logoSign"
          src="../../../public/assets/icon/iconSmsPhone.svg"
        />
        <ion-text v-if="!clickModifyPhone">
          <h1>{{ cPhoneNumber }}</h1>
        </ion-text>
        <ErrorDisplay @deleteErrorMsg="deleteErrorMsg" :errorMsg="errorMsg"/>
        <ion-item v-if="clickModifyPhone">
          <ion-label position="floating">Numéro de téléphone</ion-label>
          <ion-input
            inputmode="tel"
            v-model="newPhoneNumber"
            clearInput
            type="tel"
            placeholder="0601020304"
          ></ion-input>
        </ion-item>
        <ion-button v-if="!clickModifyPhone" v-on:click="modifyPhone()">
          Modifier Numéro
        </ion-button>
        <ion-button v-if="clickModifyPhone" v-on:click="confirmNewPhone()">
          Confirmer Numéro
        </ion-button>
        <ion-card >
          <ion-card-header>
            <ion-card-title >

              <h3>Pour entrer chez Alloopharma, entrez le code reçu par SMS 😍</h3>
            </ion-card-title>
            <ion-card-subtitle color="dark">{{msgCode}}</ion-card-subtitle>
          </ion-card-header>
          <ion-card-content>
            <ion-item>
              <ion-label position="floating">Code</ion-label>
              <ion-input v-model="code" placeholder="1234" autofocus type="number"></ion-input>
            </ion-item>
            <div class="ion-margin-top">
              <ion-button @click="sendSms"> Renvoyer code </ion-button>
              <ion-button @click="validateCode"> Valider </ion-button>
            </div>
          </ion-card-content>
        </ion-card>
      </div>
    </ion-content>
  </ion-page>
</template>

<script scoped>
import {
  IonPage,
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonItem,
  IonText,
  IonInput,
  IonLabel,
  IonButton,
} from "@ionic/vue";
import store from "@/store";
import router from '@/router'
import { updateProfile, verifyCode, askSms } from "../../services/api";
import ErrorDisplay from "../../components/ErrorDisplay";
export default {
  name: "ConfirmPhone",
  components: {
    IonContent,
    ErrorDisplay,
    IonPage,
    IonItem,
    IonText,
    IonInput,
    IonLabel,
    IonButton,
      IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  },
  data() {
    return {
      code: null,
      clickCodeSended: false,
      clickCodeValidated: false,
      clickModifyPhone: false,
      canSendCodeAgain: true,
      countSendingCode: 0,
      newPhoneNumber: "",
      errorMsg: [],
      msgCode:"",
      isCodeSendedAgain: false,
    };
  },
  mounted() {
    this.newPhoneNumber = this.cPhoneNumber;
    this.sendSms();
  },
  computed: {
    cPhoneNumber() {
      return store.getters.phoneNumber.replace("+33", 0);
    },
  },
  methods: {
    sendSms() {
      if (this.countSendingCode > 3) {
        this.errorMsg = ["Vous ne pouvez pas envoyer plus de 3 sms de confirmation"]
        return
      }
      askSms().then(() => {
        this.msgCode = "Regarde tes SMS on viens de te renvoyer un code 😘";
        this.countSendingCode++;
        this.canSendCodeAgain = false;
        setTimeout(() => this.msgCode = '', 5000);
        setTimeout(() => (this.canSendCodeAgain = true), 60000);
      })
    },
    modifyPhone() {
      this.clickModifyPhone = true;
    },
    confirmNewPhone() {
      const phoneModified = this.newPhoneNumber;
      this.clickModifyPhone = false;
      updateProfile({ phone: this.newPhoneNumber }).then(() => {
        this.canSendCodeAgain = true
      }).catch((e) => {
        this.errorMsg = [`${phoneModified} ${e.response.data.errors.phone}`];
      });
    },
    deleteErrorMsg(msg) {
      const index = this.errorMsg.indexOf(msg);
      this.errorMsg.splice(index, index + 1);
    },
    validateCode() {
      this.clickCodeValidated = true;
      verifyCode(this.code).then((isVerified) => {
        if (isVerified) {
          if (store.getters.acceptationStatus === 'En attente de complétion du profil') {
            router.push("/informations-profil")
          } else {
            router.push("/")
          }
        } else {
          this.errorMsg = ["Le code n'est pas le bon"];
          setTimeout(() => (this.errorMsg = []), 5000);
        }
      });
    },
  },
};
</script>
<style scoped>
h1 {
  color: white;
}

ion-content {
  --background: none;
  /* background-image: url("../../../public/assets/background/bgSign.svg"); */
  background-color: var(--new-blue);
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  margin: auto;
  padding: 10px;
}
</style>
